import React, { Component } from "react"
import styled from "styled-components"
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram
} from "react-icons/fa"
import Resources from "../../resources/mlp-resources.json"
import SmallCustomButton from "./small-button"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { format } from "../utils/string"
import { useSiteContent } from "../hooks/use-site-content"
import MlpLogo from "../images/svg/mlp-logo-texto.svg"

const PrimaryNavbarWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  max-width: 100%;
  transition: 0.5s;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: none;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
`

const PrimaryNavbarList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

const PrimaryNavbarLink = styled.a`
  color: var(${Resources.navbar.primary.link.color});
  font-family: var(${Resources.navbar.primary.link.font});
  font-size: ${Resources.navbar.primary.link.size};
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 22px;
  cursor: pointer;

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    margin-right: 18px;
  }
`

const PrimaryNavbarIconsList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;

  .icon-primary {
    font-size: ${Resources.navbar.primary.icon.size} !important;
  }
`

const PrimaryNavbarIcon = styled.a`
  color: var(${Resources.navbar.primary.icon.color});
  font-family: var(${Resources.navbar.primary.icon.font});
  font-size: ${Resources.navbar.primary.icon.size};
  text-decoration: none;
  margin-right: 22px;
  align-self: flex-end;

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    margin-right: 18px;
  }
`

const MlpLogoStyled = styled(MlpLogo)`
  height: 1.5em;
  fill: var(${Resources.navbar.primary.link.color});
`

class PrimaryNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIcon: false,
      scrolled: false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  /**
   * Scroll to the selected position.
   * @param {String} to
   */
  smoothTo(to) {
    const element = document.getElementById(to)
    if (element) {
      const position = element.offsetTop - Resources.navbar.offset_top
      console.log(position);
      setTimeout(() => {
        window.scrollTo(0, position)
      }, 100)
    }
  }

  /**
   * Triggered when an item is clicked.
   * @param {String} to
   */
  onLinkClick(to) {
    if (to.includes("/")) {
      //navigate(to)
    } else {
      console.log(this.props.location);
      if (this.props.location === "/") {
        this.smoothTo(to)
      } else {
        navigate("/")
        console.log("Smooth with navigation")
        setTimeout(() => this.smoothTo(to), 400)
      }
    }
  }

  /**
   * Action executed when the client button is clicked
   */
  onClickClient() {
    console.log("clicked")
  }

  /**
   * Scroll handler
   * @param {*} e
   */
  handleScroll(e) {

    /**
     * The scroll handle will be only available con root location.
     */
    if (this.props.location === "/") {

      /** Get scroll position. */
      const scrollPosition = document.scrollingElement.scrollTop

      /** Background color set null */
      let backgroundColor = null

      /** When the scroll top position is under 160px  */
      if (scrollPosition <= 160) {
        const opacity = scrollPosition / 160
        /** Setting the dynamic opacity when we scroll */
        backgroundColor = format(Resources.navbar.secondary.bg_color, [
          opacity
        ])
        document.getElementById("navbar-top").style.backgroundColor = backgroundColor

        /**
         * Logo animation implementation. The logo and request button will dissapear once
         * the scroll is on the top again.
         */
        const requestButton = document.getElementById("request-button")
        if (requestButton) {
          requestButton.style.transition = "opacity 0.25s"
          requestButton.style.opacity = 0
          this.updateIconsColor(false);

          /**
           * Timeout set to give some time to the social icons to dissapear
           * before the change of state
           */
          setTimeout(() => {
            this.setState({
              scrolled: false
            }, () => {
              const socialList = document.getElementById("social-list")
              socialList.style.transition = "opacity 0.25s"
              socialList.style.opacity = 0
              setTimeout(() => {
                socialList.style.opacity = 1
              }, 50)
            })
          }, 250)
        }
      } else {

        /**
         * This variable will help us to define which of the social list button or the
         * request button is displayed.
         */
        if (!this.state.scrolled) {

          backgroundColor = format(Resources.navbar.secondary.bg_color, ["1"])
          document.getElementById("navbar-top").style.backgroundColor = backgroundColor
          const socialList = document.getElementById("social-list")
          if (socialList) {
            socialList.style.transition = "opacity 0.25s"
            socialList.style.opacity = 0
            this.updateIconsColor(true);
            setTimeout(() => {
              this.setState({
                scrolled: true
              }, () => {

                const requestButton = document.getElementById("request-button")
                requestButton.style.transition = "opacity 0.25s"
                requestButton.style.opacity = 0
                setTimeout(() => {
                  requestButton.style.opacity = 1
                }, 50)
              })
            }, 250)

            setTimeout(() => {
            }, 1)
          }
        }
      }
    }
  }

  updateIconsColor(second) {
    const links = document.getElementsByClassName('primary-link')
    const newLinks = [...links]
    const menuButton = document.querySelector('.menu-mobile-icon')
    const svgElem = document.querySelector('.dynamic-svg')

    menuButton.style.color = second 
      ? `var(${Resources.navbar.secondary.link.color})`
      : `var(${Resources.navbar.primary.link.color})`

    newLinks.forEach(item => {
      item.style.color = second
        ? `var(${Resources.navbar.secondary.link.color})`
        : `var(${Resources.navbar.primary.link.color})`;
    });

    svgElem.style.fill = second
      ? `var(${Resources.navbar.secondary.link.color})`
      : `var(${Resources.navbar.primary.link.color})`;
  }

  componentDidMount() {
    if (this.props.scrolled) {
      this.updateIconsColor(true);
    }

    const menuIcon = document.querySelector('.menu-mobile-icon');
    if(this.props.location === '/' && document.scrollingElement.scrollTop < 160) {
      menuIcon.style.color = 'var(--primary-color)'
    }

    window.addEventListener("scroll", this.handleScroll)
  }

  /**
   * Every time the component updates, we check which color will be used for icons
   */
  componentDidUpdate() {
    const menuIcon = document.querySelector('.menu-mobile-icon');
    if(this.props.location === '/' && document.scrollingElement.scrollTop < 160) {
      menuIcon.style.color = 'var(--primary-color)'
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  /**
   * render function
   */
  render() {
    const {navbar: { social_links }} = this.props.siteContent
    return (
      <PrimaryNavbarWrapper id={"navbar-wrapper"}>
        <PrimaryNavbarList>
          <PrimaryNavbarLink
            onClick={() =>
              this.onLinkClick(
                Resources.navbar.link_home.to,
                this.props.location
              )
            }
          >
            <MlpLogoStyled className="dynamic-svg"/>
          </PrimaryNavbarLink>
          {Resources.navbar.links.map((link, key) => (
            <PrimaryNavbarLink
              className="primary-link"
              key={key}
              href={(this.props.location === '/' && link.to === "/casos-de-exito") ? link.to : "#"}
              onClick={() => this.onLinkClick(link.to)}>
              {link.label}
            </PrimaryNavbarLink>
          ))}
        </PrimaryNavbarList>
        {(!this.state.scrolled && this.props.location === "/") && (
          <PrimaryNavbarIconsList id={"social-list"}>
            <PrimaryNavbarIcon
              className="primary-icon"
              href={social_links.fb}
              target="_blank"
            >
              <FaFacebookF className="icon-primary"/>
            </PrimaryNavbarIcon>
            <PrimaryNavbarIcon
              className="primary-icon"
              href={social_links.twitter}
              target="_blank"
            >
              <FaTwitter className="icon-primary"/>
            </PrimaryNavbarIcon>
            <PrimaryNavbarIcon
              className="primary-icon"
              href={social_links.linkedin}
              target="_blank"
            >
              <FaLinkedinIn className="icon-primary"/>
            </PrimaryNavbarIcon>
            <PrimaryNavbarIcon
              className="primary-icon"
              href={social_links.instagram}
              style={{ marginRight: 0 }}
              target="_blank"
            >
              <FaInstagram className="icon-primary"/>
            </PrimaryNavbarIcon>
          </PrimaryNavbarIconsList>
        )}

        {(this.state.scrolled || this.props.location !== "/") && (
          <PrimaryNavbarIconsList id={"request-button"}>
            <SmallCustomButton
              label={Resources.navbar.secondary.button.label}
              view={Resources.navbar.secondary.button.view}
              onClick={ () =>
                this.onLinkClick("contact-form-id", this.props.location)
              }
            />
          </PrimaryNavbarIconsList>
        )}
      </PrimaryNavbarWrapper>
    )
  }
}

/**
 * Property types of PrimaryNavbar
 */
PrimaryNavbarIconsList.propTypes = {
  location: PropTypes.string,
  scrolled: PropTypes.bool
}

/**
 * Default property values for PrimaryNavBar
 */
PrimaryNavbar.defaultProps = {
  location: "/",
  scrolled: false
}

export default props => {
  const SiteContent = useSiteContent()
  return (
    <PrimaryNavbar siteContent={SiteContent} {...props} />
  )
}
