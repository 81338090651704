import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json";
import PrimaryNavbar from "./primary-navbar";
import {
  FaBars,
  FaArrowRight
} from 'react-icons/fa';
import { format } from '../utils/string'
import Fade from 'react-reveal/Fade'

const NavbarWrapper = styled.header`
  width: 100%;
  min-height: 80px;
  height: auto;
  position: fixed;
  top: 0;
  z-index: 999999999;
  background-color: ${props => props.background};
  max-width: ${props => props.mobileIconWidth};
`;

const NavbarMobileWrapper = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  
  .menu-mobile-icon {
    font-size: 2em;
    color: white;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: flex;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    display: flex;
  }
`;

const Navbar = ({ scrolled, onClickMobile, mobileIconWidth, location }) => {

  return (
    <NavbarWrapper id="navbar-top"
                  background={
                    location === "/" ?
                    format(Resources.navbar.secondary.bg_color,[0]) :
                    format(Resources.navbar.secondary.bg_color, [1])
                  }
                  mobileIconWidth={mobileIconWidth}>
                    <Fade big>
      <div className="container">
        
        <PrimaryNavbar scrolled={scrolled} location={location}/>
        <NavbarMobileWrapper>
          {
            mobileIconWidth === '40%'
              ? <FaArrowRight className="menu-mobile-icon"
                        onClick={() => onClickMobile()} />
              : <FaBars className="menu-mobile-icon"
                        onClick={() => onClickMobile()} />
          }
        </NavbarMobileWrapper>
      </div>
      </Fade>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  scrolled: PropTypes.bool,
  onClickMobile: PropTypes.func.isRequired,
  mobileIconWidth: PropTypes.string.isRequired,
  location: PropTypes.string,
  background: PropTypes.string
};

Navbar.defaultProps = {
  scrolled: false,
  location: '/',
  background: Resources.navbar.primary.bg_color
};

export default Navbar;
