import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import ContactForm from "./contact-form"
import Navbar from "./navbar"
import MobileNavbar from "./mobile-navbar"
import Footer from "./footer"

const LayoutWrapper = styled.div`
  .container {
    margin: 25px auto;
    --current-max-w: 1280px;
    padding: 0.5rem 0;
    max-width: var(--current-max-w);
  }


  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 30px;
      --current-max-w: 100%;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      padding: 0 30px;
      --current-max-w: 720px;
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    .container {
      padding: 0 30px;
      --current-max-w: 960px;
    }
  }

  /* Extra large devices (large laptops and desktops, 1280px and up) */
  @media only screen and (min-width: 1280px) {
    .container {
      padding: 0 30px;
      --current-max-w: 1140px;
    }
  }
`;

const LayoutContentWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
`;

const Layout = ({ children, fixedNavbar, showContact, location }) => {
  const [scrolled, setScrolled] = useState(fixedNavbar);
  const [openMobile, setOpenMobile] = useState(false);
  const [mobileIconWidth, setMobileIconWidth] = useState('100%');

  // For check scroll in page
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled && !fixedNavbar) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled, fixedNavbar]);

  const toggleMobileMenu = () => {
    setOpenMobile(!openMobile);
    const newWidth = !openMobile ? '40%' : '100%';
    setMobileIconWidth(newWidth);
  };

  return (
    <LayoutWrapper>
      <Navbar scrolled={scrolled}
              location={location}
              mobileIconWidth={mobileIconWidth}
              onClickMobile={() => toggleMobileMenu()} />
      <LayoutContentWrapper>
        <main>{children}</main>
        {
          showContact && <ContactForm />
        }
        <Footer />
      </LayoutContentWrapper>
      <MobileNavbar isOpen={openMobile}
                    location={location}
                    onBackdropClick={() => toggleMobileMenu()} />
    </LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fixedNavbar: PropTypes.bool,
  showContact: PropTypes.bool,
  location: PropTypes.string,
}

Layout.defaultProps = {
  fixedNavbar: false,
  showContact: true,
  location: '/'
}

export default Layout
