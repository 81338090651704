import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomButtonWrapper = styled.div`
    

    .custom-color {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .primary-button {
        color: white !important;
        background-color: var(--primary-third-color) !important;
        padding: 15px 80px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
    }

    .primary-button:hover {
        background-color: var(--four-color) !important;
        color: var(--primary-color) !important
    }

    .secondary-button {
        color: var(--primary-color) !important;
        background-color: white !important;
        padding: 15px 45px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
    }

    .secondary-button:hover {
        background-color: var(--primary-color) !important;
        color: var(--four-color) !important
    }
    
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .primary-button {
        padding: 10px 30px !important;
    }

    .secondary-button {
        padding: 10px 30px !important;
    }
  }
`;

const CustomButton = ({ label, onClick, isLoading, view, indicatorColor, containerStyle, fullWidth }) => (
    <CustomButtonWrapper style={containerStyle} indicatorColor={indicatorColor}>
        <Button variant="contained"
                fullWidth={fullWidth}
                className={view}
                onClick={onClick}
                disabled={isLoading}>
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-color" />
            }
            {
                !isLoading && label
            }
        </Button>
    </CustomButtonWrapper>
);

/**
 * Property types of CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    view: PropTypes.oneOf([
      'primary-button',
      'secondary-button'
    ]),
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
};

/**
 * Default property values for CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomButton.defaultProps = {
    isLoading: false,
    view: 'primary-button',
    indicatorColor: '--four-color',
    containerStyle: {},
    fullWidth: true
};

export default CustomButton;
