import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json";
import Drawer from '@material-ui/core/Drawer';
import {
  FaFacebookF, FaInstagram, FaLinkedinIn,
  FaMap,
  FaPhone, FaTwitter
} from "react-icons/fa"
import { navigate } from "gatsby"
import SmallCustomButton from "./small-button"
import { useSiteContent } from "../hooks/use-site-content"

const paperProps = {
  style: {
    width: '60%'
  }
};

const MenuMobileContent = styled.div`
  display: block;
  height: 100vh;
  background-color: var(${Resources.menu_mobile.bg_color});
`;

const MenuMobileList = styled.div`
  padding: 0 30px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  height: 65vh;
`;

const MenuMobileLink = styled.a`
  color: var(${Resources.menu_mobile.link.color});
  font-family: var(${Resources.menu_mobile.link.font});
  font-size: ${Resources.menu_mobile.link.size};
  text-decoration: none;
  text-transform: uppercase;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.link.size_med};
  }
`;

const MenuMobileFooter = styled.div`
  background-color: var(${Resources.menu_mobile.footer.bg_color});
  padding: 0 30px;
  display: grid;
  height: 35vh;
  justify-content: center;
  align-items: center;
`;

const MenuMobileIconLegend = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;
  
  .footer-icon {
    font-size: ${Resources.menu_mobile.footer.icon_size};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-icon {
      font-size: ${Resources.menu_mobile.footer.icon_size_med};
    }
  }
`;

const MenuMobileFooterText = styled.p`
  font-family: var(${Resources.menu_mobile.footer.font});
  font-size: ${Resources.menu_mobile.footer.size_mobile};
  color: var(${Resources.menu_mobile.footer.color});
  margin: 0;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.footer.size_med_mobile};
  }
`;

const PrimaryNavbarIconsList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  
  .icon-primary {
    font-size: ${Resources.navbar.primary.icon.size} !important;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .icon-primary {
      font-size: ${Resources.menu_mobile.footer.icon_size_med} !important;
    }
  }
`;

const PrimaryNavbarIcon = styled.a`
  color: var(${Resources.navbar.secondary.icon.color});
  font-family: var(${Resources.navbar.primary.icon.font});
  font-size: ${Resources.navbar.primary.icon.size};
  text-decoration: none;
  margin-right: 22px;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 44px;
  }
`;

const MobileNavbar = ({ isOpen, onBackdropClick, location }) => {
  const { navbar: {social_links},
          footer: {lista_telefonos, address, map_url}} = useSiteContent();
  return (
    <Drawer PaperProps={paperProps}
            open={isOpen}
            onBackdropClick={() => onBackdropClick()}
            anchor="right">
      <div className="container">
        <MenuMobileContent>
          <MenuMobileList>
            {
              Resources.navbar.links.map((link, key) =>
                <MenuMobileLink key={key}
                                onClick={() => onLinkClick(link.to, location, onBackdropClick)}>
                  {link.label}
                </MenuMobileLink>
              )
            }

            <SmallCustomButton label={Resources.menu_mobile.link_alter.label}
                               fullWidth={false}
                               align="center"
                               onClick={() => onLinkClick('contact-form-id', location, onBackdropClick)} />
          </MenuMobileList>
          <MenuMobileFooter>
            <MenuMobileIconLegend>
              <FaMap className="footer-icon" />
              <MenuMobileFooterText onClick={() => openMap(map_url)}>
                {
                  address.map((line, key) => (<span key={key}>{line} <br/></span>))
                }
              </MenuMobileFooterText>
            </MenuMobileIconLegend>
            <MenuMobileIconLegend>
              <FaPhone className="footer-icon" />
              <MenuMobileFooterText>
                {
                  lista_telefonos.map((phone, key) => (<span key={key}>{phone} <br/></span>))
                }
              </MenuMobileFooterText>
            </MenuMobileIconLegend>
            <PrimaryNavbarIconsList>
              <PrimaryNavbarIcon href={social_links.fb}
                                 target="_blank">
                <FaFacebookF className="icon-primary" />
              </PrimaryNavbarIcon>
              <PrimaryNavbarIcon href={social_links.twitter}
                                 target="_blank">
                <FaTwitter className="icon-primary" />
              </PrimaryNavbarIcon>
              <PrimaryNavbarIcon href={social_links.linkedin}
                                 target="_blank">
                <FaLinkedinIn className="icon-primary" />
              </PrimaryNavbarIcon>
              <PrimaryNavbarIcon href={social_links.instagram}
                                 style={{ marginRight: 0 }}
                                 target="_blank">
                <FaInstagram className="icon-primary" />
              </PrimaryNavbarIcon>
            </PrimaryNavbarIconsList>
          </MenuMobileFooter>
        </MenuMobileContent>
      </div>
    </Drawer>
  );
}

const openMap = (url) => {
  window.open(url, '_blank');
};

const onLinkClick = (to, location, onBackdropClick) => {
  if (to.includes("/")) {
    navigate(to)
  } else {
    if (location === "/") {
      smoothTo(to, onBackdropClick)
    } else {
      navigate("/")
      setTimeout(() => smoothTo(to, onBackdropClick), 500)
    }
  }
};

const smoothTo = (to, onBackdropClick) => {
  const element = document.getElementById(to);
  if (element) {
    const position = element.offsetTop - Resources.navbar.offset_top;
    window.scrollTo(0, position);
    onBackdropClick();
  }
};

MobileNavbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onBackdropClick: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired
};

export default MobileNavbar;
